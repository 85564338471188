import { useContext, useEffect, useRef, useState } from "react";
import { GlobalContext } from "../../App";

import illust from "../../assets/images/illust-02.png"
import illustMobile from "../../assets/images/illust-02-m.svg"
import checkBlue from "../../assets/images/icon-ic-check-blue.svg"
import checkPink from "../../assets/images/icon-ic-check-pink.svg"
import checkYellow from "../../assets/images/icon-ic-check-yellow.svg"
import classNames from "classnames";

function Section3() {
  const [triggerOffsetTop , setTriggerOffsetTop] = useState(0);
  const [animation, setAnimation] = useState(false);

  const triggerRef = useRef(null);

  const {state : {scrollBottom}} = useContext(GlobalContext);

  useEffect(() => {
    setTriggerOffsetTop(triggerRef.current.offsetTop + 100)
  },[])

  useEffect(() => {
    if(scrollBottom > triggerOffsetTop){
      setAnimation(true);
    }
  },[scrollBottom])

  return (
    <section className="section section3" id="atruck">
      <div className="container" ref={triggerRef}>
        <h3 className="section__title">
          A TRUCK으로 보다 쉽게
        </h3>
        <p className="section__detail">
          A TRUCK은 표구부터 배송-설치까지 온라인 통합 일정 예약 서비스를 제공,<br className="pc"/>
          그간 갤러리가 컬렉터와 표구사, 배송사 사이에서 일일이 연락을 취해 수행해야<br className="pc"/>
          했던 갤러리의 업무 프로세스를 간소화 합니다.
        </p>
        <div className={classNames("section3__bottom-wrap", {on : animation})}>
          <ul className="section3__process-list">
            <li className="section3__process-item">
              <img src={checkBlue} alt="check" />
              <span className="section3__process-txt">표구제작</span>
            </li>
            <li className="section3__process-item">
              <img src={checkPink} alt="check" />
              <span className="section3__process-txt">온라인 배송매칭</span>
            </li>
            <li className="section3__process-item">
              <img src={checkYellow} alt="check" />
              <span className="section3__process-txt">맞춤 설치</span>
            </li>
          </ul>
          <div className="section3__illust-wrap">
            <img src={illust} className="section3__illust section3__illust--pc" alt="illust" title="illust" />
            <img src={illustMobile} className="section3__illust section3__illust--mobile" alt="illust" title="illust" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section3;
