import classNames from "classnames";
import { useState, useEffect, useRef, useContext } from "react";
import { GlobalContext } from "../../App";
import ill1 from "../../assets/images/illust-01-01.svg"
import ill2 from "../../assets/images/illust-01-02.svg"
import ill3 from "../../assets/images/illust-01-03.svg"
import illMobile from "../../assets/images/illust-01-m.svg"

function Section1() {
  const [triggerOffsetTop , setTriggerOffsetTop] = useState(0);
  const [animation, setAnimation] = useState(false);

  const triggerRef = useRef(null);

  const {state : {scrollBottom}} = useContext(GlobalContext);
  

  useEffect(() => {
    setTriggerOffsetTop(triggerRef.current.offsetTop + 200)
  },[])

  useEffect(() => {
    if(scrollBottom > triggerOffsetTop){
      setAnimation(true);
    }
  },[scrollBottom])
  
  return (
    <section className={classNames("section section1")} id="introduction">
      <div className="container">
        <h4 className="section__category">
          Introduction
        </h4>
        <h3 className="section__title">
          작품 판매 후 표구 배송 설치까지
        </h3>
        <p className="section__detail">
          A TRUCK은 미술 시장에서 미술품 계약 직후 발생하는<br className="pc" />
          표구-배송-설치 등 모든 프로세스를 표준화, 디지털화 하여<br className="pc"/> 
          갤러리가 컬렉터-표구사/배송설치사와 긴밀하게 소통하도록 돕는<br className="pc"/>
          보다 효율적인 업무 솔루션을 제공합니다.
        </p>
        <div className="section1__bottom-wrap" ref={triggerRef}>
          <div className={classNames("section1__illust-box", {on : animation})}>
            <img src={ill1} alt="" className="section1__illust section1__illust--1 section1__illust--pc" />
            <img src={ill2} alt="" className="section1__illust section1__illust--2 section1__illust--pc" />
            <img src={ill3} alt="" className="section1__illust section1__illust--3 section1__illust--pc" />
            <img src={illMobile} alt="" className="section1__illust section1__illust--mobile" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section1;
