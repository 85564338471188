import { useState, useEffect, useContext } from "react";

//UTIL
import classNames from "classnames";
import logo from "../../assets/images/gnb-logo.svg"
import logoColor from "../../assets/images/icon-ic-gnb-logo-color.svg"
import { GlobalContext } from "../../App";

function Nav() {
  const [on , setOn] = useState(false);
  const {state : { scrollTop }} = useContext(GlobalContext);

  useEffect(() => {
    if(scrollTop){
      setOn(true);
    }else{
      setOn(false);
    }
  },[scrollTop])

  return (
    <nav className={classNames("nav", {on})}>
      <div className="container">
        {
          on
          ?
          <img className="nav__logo" src={logoColor} alt="atruck logo" title="atruck logo" />
          :
          <img className="nav__logo" src={logo} alt="atruck logo" title="atruck logo" />
        }
        <ul className="nav__btns">
          <li className="nav__btn">
            <a href="#atruck">ATRUCK</a>
          </li>
          <li className="nav__btn">
            <a href="#contact">CONTACT</a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Nav;
