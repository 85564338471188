import arrow from "../../assets/images/icon-ic-arrowdown.svg"

function Header() {
  return (
    <header className="header">
      <div className="container">
        <h3 className="header__sub-title rise-up">
          The way we work, A TRUCK
        </h3>
        <h2 className="header__title rise-up">
          <strong>미술품 표구 - 배송 - 설치</strong><br/>
          프로세스의 표준
        </h2>
        <a className="header__more-wrap rise-up" href="#introduction">
          <img src={arrow} alt="more icon" title="more icon" className="header__more-icon" />
          <span className="header__more-txt">More</span>
        </a>
      </div>
    </header>
  );
}

export default Header;
