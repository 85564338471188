function Contact(){
  return (
    <section className="contact" id="contact">
      <div className="container">
        <strong className="contact__message">A TRUCK  바로 시작해보세요!</strong>
        <a className="contact__send-mail" href="mailto:atruck.official@gmail.com">문의하기</a>
      </div>
    </section>
  )
}

export default Contact;