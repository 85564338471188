import { useRef, useEffect, useState, useContext } from "react";
import lottie from "lottie-web";
import { GlobalContext } from "../../App";

function Section5() {
  const calendarContainer = useRef(null);
  const truckContainer = useRef(null);


  const {state : {scrollBottom}} = useContext(GlobalContext);

  useEffect(() => {
    document.querySelectorAll('.section5__card').forEach((el,idx) => {
      if(scrollBottom > el.offsetTop + 100){
        const hasClass = el.classList.contains("on")
        if(idx === 0 && !hasClass){
          el.classList.add("on")
          lottie.loadAnimation({
            container: calendarContainer.current,
            renderer: 'svg',
            loop: false,
            autoplay:true,
            animationData:require("../../assets/json/calendar.json")
          })
        }
        
        if(idx===1 && !hasClass){
          el.classList.add("on")
          lottie.loadAnimation({
          container: truckContainer.current,
          renderer: 'svg',
          loop: false,
          autoplay:true,
          animationData:require("../../assets/json/truck.json")
        })
        }
      }
    })
  },[scrollBottom])
  return (
    <section className="section section5">
      <div className="container">
        <h4 className="section__category">
          OUR SERVICES
        </h4>
        <h3 className="section__title">
          자동 배송 일정 매칭
        </h3>
        <p className="section__detail">
          일정 선택 - 배송사 자동매칭 - 작품배송,설치까지 
        </p>
        <div className="section5__card-wrap">
          <div className="section5__card">
            <figure className="section5__card-icon" ref={calendarContainer}></figure>
            <figcaption className="section5__card-detail">
              <h5 className="section5__card-title">Schedule</h5>
              <p className="section5__card-txt">
              편리한 일정 예약 서비스<br />A TRUCK 앱에서는 표구사/배송사의 <br />가능 일정을 한 눈에 볼 수 있어 효율적인 <br /> 일정 관리가 가능해집니다.
              </p>
            </figcaption>
          </div>
          <div className="section5__card">
            <figure className="section5__card-icon" ref={truckContainer}></figure>
            <figcaption className="section5__card-detail">
              <h5 className="section5__card-title">Quality</h5>
              <p className="section5__card-txt">
                언제 어디서나 표준화된 서비스와 메뉴얼을<br/>기반으로 하여 퀄리티 가 보장되는<br/>배송-설치 서비스를 제공합니다.
              </p>
            </figcaption>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section5;
