import topArrow from "../../assets/images/icon-ic-top-bar-arrow.svg";
import check from "../../assets/images/icon-ic-check-blue.svg";
import { useContext, useEffect, useRef, useState } from "react";
import { GlobalContext } from "../../App";
import classNames from "classnames";

function Section2() {
  const { state: { scrollBottom } } = useContext(GlobalContext);

  const triggerRef = useRef(null);
  const graphRef = useRef(null);

  //const [triggerOffsetTop , setTriggerOffsetTop] = useState(0);
  const [graphOffsetTop, setGraphOffsetTop] = useState(0);
  const [animation, setAnimation] = useState({
    container: false,
    graph: false
  });


  useEffect(() => {
    setGraphOffsetTop(graphRef.current.offsetTop)
  }, [])

  useEffect(() => {
    // if(scrollBottom > triggerOffsetTop){
    //   setAnimation({
    //     ...animation,
    //     container : true
    //   });
    // }

    if (scrollBottom > graphOffsetTop + 500) {
      setAnimation({
        graph: true,
        container: true
      });
    }
  }, [scrollBottom])

  return (
    <section className={classNames("section section2")}>
      <div className="container" ref={triggerRef}>
        <h4 className="section__category">
          Market Status
        </h4>
        <h3 className="section__title">
          미술시장 현황
        </h3>
        <p className="section__detail">
          화랑, 아트페어, 경매 시장에서 거래된 미술 작품의 금액은 작년 9000억원을 돌파했고,<br />
          올해는 1조를 넘어설 것으로 예상되고 있습니다.
        </p>
        <div className="section2__bottom-wrap" ref={graphRef}>
          <div className="section2__statistics-wrap">
            <h5 className="section2__statistics-title">
              2020년도 대비 2021년 미술품<br />
              거래액 증가율
            </h5>
            <span className="section2__statistics-percent">
              +142%
            </span>
            <div className={"section2__graph-wrap"}>
              <div className="section2__graph-item-image" />
              <div className="section2__right-wrap">
                <div className="section2__graph-item" style={{ marginLeft: 10 }}>
                  <div className="section2__graph-bar--2020"></div>
                  <div className="section2__total-price">3811억</div>
                  <div className="section2__total-year">2020년</div>
                </div>
                <div className="section2__graph-item">
                  <div className="section2__graph-bar--2021"></div>
                  <div className="section2__total-price">9223억</div>
                  <div className="section2__total-year">2021년</div>
                </div>
              </div>
            </div>
          </div>
          <div className="section2__status-wrap">
            <div className="section2__status-item">
              <div className="section2__status-top-wrap">
                <img src={topArrow} alt="arrow" title="arrow" />
              </div>
              <div className="section2__status-item-title">Status</div>
              <div className="section2__status-item-sub-title">
                전통적인 업무 방식으로 인한 <br className="pc" />
                표구-배송-설치 지연
              </div>
              <div className="section2__status-item-detail">
                미술작품의 거래 건수는 점점 <br className="pc" />
                늘어났지만, 여전히 대면과 서면<br className="pc" />
                위주의 업무 방식이 주로 이루는<br className="pc" />
                갤러리의 업무 방식으로는<br className="pc" />
                늘어난 주문을 빠르게 처리할 수<br className="pc" />
                없었습니다.
              </div>
            </div>
            <div className="section2__status-item">
              <div className="section2__status-top-wrap section2__status-top-wrap--column">
                <img src={check} alt="check" title="check" />
                <div className="section2__status-top-border"></div>
              </div>
              <div className="section2__status-item-title section2__status-item-title--blue">What we do</div>
              <div className="section2__status-item-sub-title">
                미술 시장 변화에 따른 새로운<br className="pc" />
                시스템의 필요성 증대
              </div>
              <div className="section2__status-item-detail">
                미술 시장의 새로운 변화를 기존<br className="pc" />
                미술 매매 프로세스가 따라가지<br className="pc" />
                못하고 있는 만큼, 미술 매매<br className="pc" />
                프로세스에 새로운 시스템의<br className="pc" />
                필요성이 증대되고 있습니다.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section2;
