import logo from "../../assets/images/icon-ic-footer-logo.svg"
import insta from "../../assets/images/icon-ic-footer-instagram.svg"

function Footer(){
  const handleSocialMedia = () => window.alert("준비중입니다.")
  return (
    <footer className="footer">
      <div className="container">
        <img src={logo} alt="logo" title="logo" className="footer__logo" />
        <button onClick={handleSocialMedia} title="인스타그램으로 이동">
          <img src={insta} alt="인스타그램"/>
        </button>
      </div>
    </footer>
  )
}

export default Footer;