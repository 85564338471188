import { useEffect, useState, createContext, useReducer} from 'react';
import './App.scss';
import {Header, Nav, Section1, Section2, Section3, Section4, Section5, Contact, Footer} from "./sections";


export const GlobalContext = createContext();

function Atruck() {
  const [state, dispatch] = useReducer((state, data) => ({...state,...data}), {
    scrollTop : 0,
    scrollBottom : 0
  })

  useEffect(() => {
    window.addEventListener("scroll", function(){
      dispatch({
        scrollTop : window.pageYOffset,
        scrollBottom : window.pageYOffset + window.innerHeight
      })

      this.document.querySelectorAll('.section').forEach(el => {
        const paddingTop =  parseInt(window.getComputedStyle(el).paddingTop,10);
        if(window.pageYOffset + window.innerHeight > el.offsetTop + paddingTop){
          el.classList.add('on')
        }
      })
    })
  },[])

  return (
    <GlobalContext.Provider value={{state}}>
      <div className="app-container">
        <Nav />
        <Header />
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <Contact />
        <Footer />
      </div>
    </GlobalContext.Provider>
  );
}

export default Atruck;
