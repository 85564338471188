import { useRef, useEffect, useState, useContext } from "react";
import lottie from "lottie-web";
import { GlobalContext } from "../../App";

function Section4() {
  const imageContainer = useRef(null);
  const rocketContainer = useRef(null);
  
  const {state : {scrollBottom}} = useContext(GlobalContext);

  // useEffect(()=>{
  //   lottie.loadAnimation({
  //     container: imageContainer.current,
  //     renderer: 'svg',
  //     loop: false,
  //     autoplay:true,
  //     animationData:require("../../assets/json/image.json")
  //   })
  //   lottie.loadAnimation({
  //     container: rocketContainer.current,
  //     renderer: 'svg',
  //     loop: false,
  //     autoplay:true,
  //     animationData:require("../../assets/json/rocket.json")
  //   })
  // },[])

  useEffect(() => {
    document.querySelectorAll('.section4__card').forEach((el,idx) => {
      if(scrollBottom > el.offsetTop + 100){
        const hasClass = el.classList.contains("on")
        if(idx === 0 && !hasClass){
          el.classList.add("on")
          lottie.loadAnimation({
            container: imageContainer.current,
            renderer: 'svg',
            loop: false,
            autoplay:true,
            animationData:require("../../assets/json/image.json")
          })
        }
        
        if(idx===1 && !hasClass){
          el.classList.add("on")
          lottie.loadAnimation({
          container: rocketContainer.current,
          renderer: 'svg',
          loop: false,
          autoplay:true,
          animationData:require("../../assets/json/rocket.json")
        })
        }
      }
    })
  },[scrollBottom])
  return (
    <section className="section section4">
      <div className="container">
        <h4 className="section__category">
          OUR SERVICES
        </h4>
        <h3 className="section__title">
          표구 주문 제작
        </h3>
        <p className="section__detail">
          디자인 선택 - 주문 - 픽업후 표구 제작까지<br/>
          표준화된 시스템으로 한번에
        </p>
        <div className="section4__card-wrap">
          <div className="section4__card">
            <figure className="section4__card-icon" ref={imageContainer}></figure>
            <figcaption className="section4__card-detail">
              <h5 className="section4__card-title">Standardization</h5>
              <p className="section4__card-txt">
                표준화된 샘플로 오차 없이<br/> 
                A TRUCK이 제공하는 표구 키트를 통해<br/> 
                여러 액자 시안을 직접 보고<br/> 
                그림에 맞는 액자를 고를 수 있습니다.
              </p>
            </figcaption>
          </div>
          <div className="section4__card">
            <figure className="section4__card-icon" ref={rocketContainer}></figure>
            <figcaption className="section4__card-detail">
              <h5 className="section4__card-title">Onestop</h5>
              <p className="section4__card-txt">
                픽업-표구제작까지 한 번에<br />
                별도의 배송 일정 조율 필요 없이,<br /> 
                표구 주문과 동시에 픽업이 이루어집니다. 
              </p>
            </figcaption>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section4;
